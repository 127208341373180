import { Button, IconButton, Pagination } from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { IconFileDownload } from '@tabler/icons-react';
function ListHistory(props) {
  let { rows, columns, setOpenHistory, setOpenArea } = props;
  const [data, setData] = useState(rows);
  const [isLoading, setIsLoading] = useState(true);
  const [a, setA] = useState("");
  const CustomToolbar = () => {
    return (
      <div style={{ display: "flex" }}>
        <GridToolbarContainer sx={{ display: "flex" }}>
          <GridToolbarFilterButton style={{ fontSize: "0.6rem" }} />
          <GridToolbarExport style={{ fontSize: "0.6rem" }} />
        </GridToolbarContainer>
        {/* <div style={{width:"45%", display:"flex"}}>
          <div style={{width:"50%", display:"flex", alignItems:"center"}}>
            <span style={{width:"20%", fontWeight:700}}>From: </span>
            <input
            style={{width:"80%", fontFamily:"initial"}}
              type="date"
              onChange={(e) => {
                console.log("2023-05-25" === e.target.value);
              }}
            />
          </div>
          <div style={{width:"50%", display:"flex", alignItems:"center"}}>
            <span style={{width:"20%", fontWeight:700, textAlign:"right"}}>To:</span>
            <input
            style={{width:"80%", fontFamily:"initial"}}
              type="date"
              onChange={(e) => {
                console.log("2023-05-25" === e.target.value);
              }}
            />
          </div>
          
        </div> */}
        {/* <IconButton><IconFileDownload width={20} height={20}/></IconButton> */}
      </div>
    );
  };
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setOpenHistory(false);
            setOpenArea(true);
          }}
        >
          Report box
        </Button>
        <Pagination
          color="primary"
          size="small"
          count={pageCount}
          page={page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        ></Pagination>
      </>
    );
  }
  useEffect(() => {
    var temp = [];
    var index = 1;
    rows.forEach((row) => {
      temp.push({ ...row, id_table: index });
      index++;
    });
    setData(temp);
    setIsLoading(false);
  }, [rows]);

  return (
    <>
      {!isLoading && (
        <DataGrid
          getRowId={(row) => row.id_table}
          initialState={{
            sorting: {
              sortModel: [{ field: "created_at", sort: "desc" }],
            },
          }}
          rows={data}
          columns={columns}
          rowHeight={35}
          // hideFooter="true"
          pageSize={100}
          hideFooterRowCount="true"
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          disableColumnMenu
          disableSelectionOnClick
          sx={{
            fontSize: "0.7rem",
            backgroundColor: "white",
            overflowX: "hidden",
          }}
        />
      )}
    </>
  );
}

export default ListHistory;
