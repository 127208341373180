import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { getAllItem } from "../../api/DeviceAPI";
import moment from "moment/moment";
import ListItems from "../DataGrid/ListItems";

const ManageItems = () => {
  const [isloading, setIsLoading] = useReducer((x) => x + 1, 0);
  const [allItems, setAllItems] = useState([]);
  const [rows, setRows] = useState([]);
  
  const columns = [
    {
      field: "created_at",
      headerName: "Time",
      width: 150,
      renderCell: (params) => {
        return moment(parseInt(params.row.created_at)).format(
          "HH:mm:ss-DD/MM/YYYY"
        );
      },
    },
    { field: "item_PID", headerName: "PID Item", width: 150 },
    { field: "item_SN", headerName: "SN Item", width: 150, renderCell: (params) => {
      if(params.row.SN_parent_item === params.row.item_SN){
        return <div style={{backgroundColor:"#fcc3c3f0", display:"flex", alignItems:"center"}}>{params.row.item_SN}</div>
      }
    }  },
    { field: "item_desrc", headerName: "Description", width: 650 },
    { field: "SN_parent_item", headerName: "Parent SN", width: 150, renderCell: (params) => {
      if(params.row.SN_parent_item === params.row.item_SN){
        return <div style={{backgroundColor:"#fcc3c3f0", display:"flex", alignItems:"center"}}>{params.row.SN_parent_item}</div>
      }
    } },
  ];
  const getAllItems = async () => {
    try {
      const res = await axios.post(getAllItem);
      setAllItems(res.data.pData.sort(function (a, b) {
        if (a.created_at > b.created_at) {
          return -1;
        }
        if (a.created_at < b.created_at) {
          return 1;
        }
        return 0;
      }));
      setRows(res.data.pData);
    } catch (error) {
      console.log(error);
    }
  };

  const setTimeSpace = (from, to) => {
    setAllItems(
      rows.filter((i) => parseInt(i.created_at) > Date.parse(from) && parseInt(i.created_at) < Date.parse(to)+86399000).sort(function (a, b) {
        if (a.created_at < b.created_at) {
          return -1;
        }
        if (a.created_at > b.created_at) {
          return 1;
        }
        return 0;
      })
    );
    // setIsLoading();
  };
  useEffect(() => {
    getAllItems();
  }, [isloading]);
  return (
    <div className="viewHome" style={{ position: "relative" }}>
      <ListItems
        columns={columns}
        rows={allItems}
        // setFrom={setFrom}
        // setTo={setTo}
        getAllItems={getAllItems}
        setTimeSpace={setTimeSpace}
      ></ListItems>
    </div>
  );
};

export default ManageItems;
