import { Modal } from "@mantine/core";
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { IconCirclePlus } from "@tabler/icons-react";
import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import {
  addLineForStation,
  editLine,
  editStationInfo,
  enableLine,
  getAllLineOfStation,
  getAllStation,
} from "../../api/DeviceAPI";
import "./NetworkSetting.css";

const NetworkSetting = () => {
  const [isloading, setIsLoading] = useReducer((x) => x + 1, 0);
  const [openedEdit, setOpenedEdit] = useState(false);
  const [editStation, setEditStation] = useState(true);
  const [allStation, setAllStation] = useState([]);
  const [allLine, setAllLine] = useState([]);
  const [station, setStation] = useState(0);
  const [infoStation, setInfoStation] = useState({});
  const [line, setLine] = useState({});
  const [selectStation, setSelectStation] = useState(2);
  const [infoLine, setInfoLine] = useState({
    line: "",
    port: "",
    clear: "",
    ip: "",
  });

  const getAllSta = async () => {
    try {
      const res = await axios.post(getAllStation);
      setAllStation(
        res.data.pData.sort(function (a, b) {
          if (a.sta_name < b.sta_name) {
            return -1;
          }
          if (a.sta_name > b.sta_name) {
            return 1;
          }
          return 0;
        })
      );
      setInfoStation(res.data.pData[station]);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllLineOfSta = async (id) => {
    try {
      const res = await axios.post(getAllLineOfStation, { id: id });
      setAllLine(res.data.pData);
    } catch (error) {
      console.log(error);
    }
  };

  const addLine = async (id) => {
    try {
      if(infoLine.line===""||infoLine.port===""||infoLine.clear===""||infoLine.ip===""){
        toast.error("Error. Please check the infomation of the line")
      }else{
        const res = await axios.post(addLineForStation, {
          number: infoLine.line,
          port: infoLine.port,
          clear: infoLine.clear,
          ip: infoLine.ip,
          station: selectStation,
        });
        setAllLine(res.data);
        setIsLoading();
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const editInfoLine = async () => {
    try {
      const res = await axios.post(editLine, {
        id_line: line.id_line,
        line_number: line.line_number,
        line_port: line.line_port,
        line_clear: line.line_clear,
        line_ip: line.line_ip,
      });
      setOpenedEdit(false);
      setIsLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const editInfoStation = async () => {
    try {
      const res = await axios.post(editStationInfo, {
        id: infoStation.id_station,
        ip: infoStation.sta_ip,
        tftp: infoStation.sta_tftp,
        network: infoStation.sta_network,
        netmask: infoStation.sta_netmask,
        gateway: infoStation.sta_gateway,
        gateway_line: infoStation.gateway_line,
        netmask_line: infoStation.netmask_line,
        APC1: infoStation.sta_APC1,
        APC2: infoStation.sta_APC2,
        portTelnet: infoStation.sta_port_telnet,
        APC1_port: infoStation.APC1_port,
        APC2_port: infoStation.APC2_port,
      });
      if (res.data.pCode === 1) {
        setEditStation(true);
        toast.success("Edit station success!");
        setIsLoading();
      } else {
        toast.error("Edit station fail");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSta();
    if (selectStation !== undefined) {
      getAllLineOfSta(selectStation);
    }
  }, [isloading]);

  return (
    <div className="viewHome">
      {/* <div className="titleContent">
        <span>NETWORK SETTING</span>
      </div> */}

      <div className="divStation">
        <FormControl sx={{ minWidth: 170}}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Station
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            style={{fontSize:"0.8rem"}}
            value={station}
            onChange={(e) => {
              setStation(e.target.value);
              setInfoStation(allStation[e.target.value]);
            }}
            size="small"
            autoWidth
            label="Station"
          >
            {allStation.length > 0 &&
              allStation.map((u, index) => (
                <MenuItem
                  key={index}
                  sx={{ m: 1, minWidth: 200 }}
                  value={index}
                  onClick={() => {
                    getAllLineOfSta(u.id_station);
                    setSelectStation(u.id_station);
                  }}
                >
                  {u.sta_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          style={{ marginLeft: "2%" }}
          variant={editStation ? "outlined" : "contained"}
          onClick={() => {
            setEditStation(!editStation);
            if (editStation === false) {
              editInfoStation();
            }
          }}
          size="small"
        >
          {editStation ? "Setting station" : "Save"}
          
        </Button>
      </div>
      <div className="info">
        <div className="divInfoStation">
          {infoStation !== undefined && allStation.length > 0 ? (
            <>
              {" "}
              <div className="divInfo">
                {" "}
                <div className="spanInfo">
                  <span>Host IP Address: </span>
                </div>
                <Input
                  id="inputStation"
                  value={infoStation.sta_ip}
                  onChange={(e) => {
                    setInfoStation({ ...infoStation, sta_ip: e.target.value });
                  }}
                  disabled={editStation}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <div className="spanInfo">
                  <span>Port telnet: </span>
                </div>
                <Input
                  id="inputStation"
                  value={infoStation.sta_port_telnet}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      sta_port_telnet: e.target.value,
                    });
                  }}
                  disabled={editStation}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">TFTP Server: </span>
                <Input
                  id="inputStation"
                  value={infoStation.sta_tftp}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      sta_tftp: e.target.value,
                    });
                  }}
                  disabled={editStation}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">Netmask: </span>
                <Input
                  id="inputStation"
                  value={infoStation.sta_netmask}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      sta_netmask: e.target.value,
                    });
                  }}
                  disabled={editStation}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">Default-gateway: </span>
                <Input
                  id="inputStation"
                  value={infoStation.sta_gateway}
                  disabled={editStation}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      sta_gateway: e.target.value,
                    });
                  }}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">APC1: </span>
                <Input
                  id="inputStation"
                  value={infoStation.sta_APC1}
                  disabled={editStation}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      sta_APC1: e.target.value,
                    });
                  }}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">APC1 Port: </span>
                <Input
                  id="inputStation"
                  value={infoStation.APC1_port}
                  disabled={editStation}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      APC1_port: e.target.value,
                    });
                  }}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">APC2: </span>
                <Input
                  id="inputStation"
                  value={infoStation.sta_APC2}
                  disabled={editStation}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      sta_APC2: e.target.value,
                    });
                  }}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">APC2 Port: </span>
                <Input
                  id="inputStation"
                  value={infoStation.APC2_port}
                  disabled={editStation}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      APC2_port: e.target.value,
                    });
                  }}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">Netmask line: </span>
                <Input
                  id="inputStation"
                  value={infoStation.netmask_line}
                  disabled={editStation}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      netmask_line: e.target.value,
                    });
                  }}
                  size="small"
                ></Input>
              </div>
              <div className="divInfo">
                {" "}
                <span className="spanInfo">Default-gateway line: </span>
                <Input
                  id="inputStation"
                  value={infoStation.gateway_line}
                  disabled={editStation}
                  onChange={(e) => {
                    setInfoStation({
                      ...infoStation,
                      gateway_line: e.target.value,
                    });
                  }}
                  size="small"
                ></Input>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="divAddLine">
            {station !== undefined && allLine.length < 16 ? (
              <>
                <IconButton className="" onClick={addLine}>
                  <IconCirclePlus color="green" />
                </IconButton>
                <div className="">
                  <TextField
                    size="small"
                    variant="standard"
                    label="Line"
                    value={infoLine.line}
                    onChange={(e) => {
                      setInfoLine({ ...infoLine, line: e.target.value });
                    }}
                    sx={{ width: "40px", marginRight: "20px" }}
                  />
                </div>
                <div className="">
                  <TextField
                    id=""
                    size="small"
                    variant="standard"
                    label="Port"
                    value={infoLine.port}
                    onChange={(e) => {
                      setInfoLine({ ...infoLine, port: e.target.value });
                    }}
                    sx={{ width: "70px", marginRight: "20px" }}
                  />
                </div>
                <div className="">
                  <TextField
                    size="small"
                    variant="standard"
                    label="Clear"
                    value={infoLine.clear}
                    onChange={(e) => {
                      setInfoLine({ ...infoLine, clear: e.target.value });
                    }}
                    sx={{ width: "40px", marginRight: "20px" }}
                  />
                </div>
                <div className="">
                  <TextField
                    size="small"
                    variant="standard"
                    label="IP Address"
                    value={infoLine.ip}
                    onChange={(e) => {
                      setInfoLine({ ...infoLine, ip: e.target.value });
                    }}
                    sx={{ width: "150px", marginRight: "20px" }}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="divInfoLine">
          <div className="infoLineTitle">
            <div style={{ position: "absolute", left: -5 }}>Port </div>
            <div style={{ position: "absolute", left: "20%" }}>
              Telnet Port{" "}
            </div>
            <div style={{ position: "absolute", left: "40%" }}>Clear Line</div>
            <div style={{ position: "absolute", left: "60%" }}>IP Address</div>
            <div style={{ position: "absolute", left: "80%" }}>Enable</div>
            <div></div>
          </div>
          {allLine.length > 0
            ? allLine.map((u, index) => (
                <Tooltip
                  title={"Edit line " + u.line_number}
                  placement="right-end"
                >
                  <div key={index} style={{ display: "flex" }}>
                    <div
                      className="infoLine"
                      onClick={() => {
                        setLine(u);
                        setOpenedEdit(true);
                      }}
                    >
                      <div className="lineName">Line {u.line_number}: </div>
                      <div> {u.line_port} </div>
                      <div>{u.line_clear} </div>
                      <div>{u.line_ip} </div>
                    </div>
                    <div style={{ width: "25%", textAlign: "center" }}>
                      <Switch
                        size="small"
                        checked={u.enable === 1 ? true : false}
                        onChange={async (e) => {
                          await axios.post(enableLine, {
                            id: u.id_line,
                            status: u.enable * -1,
                          });
                          setIsLoading();
                        }}
                      ></Switch>{" "}
                    </div>
                  </div>
                </Tooltip>
              ))
            : ""}
        </div>
      </div>
      <Modal
        opened={openedEdit}
        onClose={() => {
          setOpenedEdit(false);
        }}
        title={
          <div style={{ fontSize: "1.3rem", fontWeight: "700" }}>Edit line</div>
        }
      >
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>Line:</span>

        <>
          <Input
            value={line.line_number}
            onChange={(e) => {
              setLine({ ...line, line_number: e.target.value });
            }}
          />
          <br></br>
        </>

        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          Telnet Port
        </span>

        <>
          <Input
            value={line.line_port}
            onChange={(e) => {
              setLine({ ...line, line_port: e.target.value });
            }}
          />
          <br></br>
        </>
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          Clear Line
        </span>
        <>
          <Input
            value={line.line_clear}
            onChange={(e) => {
              setLine({ ...line, line_clear: e.target.value });
            }}
          />
          <br></br>
        </>
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          IP Address
        </span>
        <>
          <Input
            value={line.line_ip}
            onChange={(e) => {
              setLine({ ...line, line_ip: e.target.value });
            }}
          />
          <br></br>
        </>
        {/* <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          Default-gateway
        </span>
        <>
          <Input
            value={line.line_gateway}
            onChange={(e) => {
              setLine({ ...line, line_gateway: e.target.value });
            }}
          />
          <br></br>
        </> */}
        <div
          style={{ width: "100%", textAlign: "center", margin: "2vh 0 0 0" }}
        >
          <Button
            variant="contained"
            sx={{ textTransform: "none", margin: "0 5% 0 0" }}
            onClick={() => editInfoLine()}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default NetworkSetting;
