import { Select as SM } from "@mantine/core";
import {
  IconButton,
  TextField
} from "@mui/material";
import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import {
  addError,
  deleteError,
  getAllError,
  getAllModel,
} from "../../api/DeviceAPI";
import "./ManageError.css";
const ManageError = () => {
  const [infoErrAdd, setInfoErrAdd] = useState({ model: "", message: "" });
  const [allError, setAllError] = useState([]);
  const [allModel, setAllModel] = useState([]);
  const [idModel, setIdModel] = useState("");
  const [isloading, setIsLoading] = useReducer((x) => x + 1, 0);

  const getAllErr = async () => {
    try {
      const res = await axios.post(getAllError);
      setAllError(res.data.pData);
    } catch (error) {
      console.log(error);
    }
  };

  const addErr = async () => {
    try {
      const res = await axios.post(addError, infoErrAdd);
      if (res.data.pCode === 1) {
        toast.success("Add success");
        // setInfoErrAdd({ model: "", message: "" });
        // setIdModel("")
        setIsLoading();
      } else {
        toast.error("Add Fail");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteErr = async (id) => {
    try {
      const res = await axios.post(deleteError, { id: id });
      if (res.data.pCode === 1) {
        toast.success("Delete success");
        setIsLoading();
      } else {
        toast.error("Delete Fail");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllModels = async () => {
    let temp = [];
    try {
      const res = await axios.post(getAllModel);
      for (let i = 0; i < res.data.pData.length; i++) {
        temp.push(res.data.pData[i].id_model);
      }
      temp.unshift("All");
      setAllModel(temp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllErr();
    getAllModels();
  }, [isloading]);
  
  return (
    <div className="viewHome">
      {/* <div className="titleContent">
        <span>MANAGE ERRORS</span>
      </div> */}

      <div>
        <div style={{ width: "70%", margin:"0 0 0 15%" }}>
          <div className="divTitle">
            <span style={{ margin: "0 0 0 10%" }}>Models</span>
            <span style={{ margin: "0 0 0 20%" }}>Error messages</span>
          </div>
          <div style={{ overflow: "auto", maxHeight: "60vh" }}>
            {allError.length > 0 &&
              allError.map((err, index) => (
                <div className="divList" key={index}>
                  <IconButton
                    className="iconDelete"
                    onClick={() => deleteErr(err.id_error)}
                  >
                    <IconCircleMinus color="red" />
                  </IconButton>
                  <div className="inputModel">
                    <TextField
                      size="small"
                      variant="standard"
                      value={err.err_model}
                    />
                  </div>
                  <div>
                    <TextField
                      id="inputError"
                      size="small"
                      variant="standard"
                      value={err.err_message.replace(/\~\"\~/g, "'")}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="divInput">
          <div className="inputModel">
            <IconButton className="iconAdd" onClick={() => addErr()}>
              <IconCirclePlus color="green" />
            </IconButton>
            <SM
              placeholder="Pick one"
              data={allModel}
              onChange={(e) => {
                setIdModel(e);
                setInfoErrAdd({ ...infoErrAdd, model: e });
              }}
              searchable
            />
          </div>
          <div className="inputError">
            <TextField
              id="inputError"
              size="small"
              variant="standard"
              label="Error"
              value={infoErrAdd.message}
              onChange={(e) =>
                setInfoErrAdd({ ...infoErrAdd, message: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageError;
