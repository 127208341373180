// const API = "http://localhost:8000/api"
const API = process.env.REACT_APP_API_SERVER_ADDRESS;
// const API = "https://auto.danielvu.com/api"

export const getAllError = API + "/device/getAllError";
export const addError = API + "/device/addError";
export const deleteError = API + "/device/deleteError";

export const getAllValue = API + "/device/getAllValue";
export const addValue = API + "/device/addValue";
export const deleteValue = API + "/device/deleteValue";

export const getAllItem = API + "/item/getAllItem";
export const addItem = API + "/item/addItem";
export const getAllItemBySNParent = API + "/item/getAllItemBySNParent";

export const getAllStation = API + "/station/getAllStation";
export const getAllLineOfStation = API + "/station/getAllLineOfStation";
export const addLineForStation = API + "/station/addLineForStation";
export const editLine = API + "/station/editLine";
export const enableLine = API + "/station/enableLine";
export const editStationInfo = API + "/station/editStation";

export const getAllGroup = API + "/group/getAllGroup";
export const getAllModel = API + "/group/getAllModel";
export const addGroup = API + "/group/addGroup";
export const addModel = API + "/group/addModel";
export const getAllModelOfGroup = API + "/group/getAllModelOfGroup";
export const getAllGroupOfModel = API + "/group/getAllGroupOfModel";
export const getAllCmdOfGroup = API + "/group/getAllCmdOfGroup";
export const getAllCmdOfModel = API + "/group/getAllCmdOfModel";
export const editGroup = API + "/group/editGroup";
export const editModel = API + "/group/editModel";
export const deleteModel = API + "/group/deleteModel";
export const deleteGroup = API + "/group/deleteGroup";
export const checkActionModel = API + "/group/checkActionModel";

export const getListLastLog = API + "/log/getListLastLog";
export const getAllLogOfStation = API + "/log/getAllLogOfStation";
export const changeCommand = API + "/log/changeCommand";

export const testDevices = API + "/testDevices";
export const installIOS = API + "/installIOS";
export const autoTest = API + "/autoTest";
export const manualTest = API + "/manualTest";
export const powerControl = API + "/powerControl";
export const breakPassword_36_38 = API + "/breakPassword_36_38";
export const breakPassword_2960_3560_3750 =
  API + "/breakPassword_2960_3560_3750";
export const updateIOS = API + "/updateIOS";
export const downloadFile = API + "/downloadFile";
export const cancelProcess = API + "/cancelProcess";

export const catchError = API + "/catchError";
// export const testSocket = API + "/testSocket"

export const checkLogin = API + "/account/checkLogin";
export const checkToken = API + "/account/checkToken";
export const getAllIOS = API + "/readLog";
export const getListCisco = API + "/getListCisco";
export const openTerminal = API + "/openTerminal";