import { Select as SM } from "@mantine/core";
import { IconButton, TextField } from "@mui/material";
import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";
import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import {
  addError,
  addValue,
  deleteError,
  deleteValue,
  getAllError,
  getAllModel,
  getAllValue,
} from "../../api/DeviceAPI";
import "./ManageValue.css";
const ManageValue = () => {
  const [keyValue, setKeyValue] = useState({ key: "", value: "" });
  const [allValue, setAllValue] = useState([]);
  const [allKey, setAllKey] = useState([]);
  const [isloading, setIsLoading] = useReducer((x) => x + 1, 0);
  const [selectKey, setSelectKey] = useState("");
  const getAllKeyValue = async () => {
    let temp = ["All"];
    try {
      const res = await axios.post(getAllValue, {});
      res.data.pData.map((u) => {
        if (temp.includes(u.key) === false) {
          temp.push(u.key);
        }
      });
      setAllKey(temp);
      setAllValue(
        res.data.pData.sort(function (a, b) {
          if (a.key < b.key) {
            return -1;
          }
          if (a.key > b.key) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addKeyValue = async () => {
    try {
      const res = await axios.post(addValue, {
        key: keyValue.key,
        value: keyValue.value,
      });
      if (res.data.pCode === 1) {
        toast.success("Add value success!");
        setIsLoading();
      } else {
        toast.error("Add value fail!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteKeyValue = async (id) => {
    try {
      const res = await axios.post(deleteValue, {
        id: id,
      });
      if (res.data.pCode === 1) {
        toast.success("Delete value success!");
        setIsLoading();
      } else {
        toast.error("Delete value fail!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllKeyValue();
  }, [isloading]);
  return (
    <div className="viewHome" style={{ position: "relative" }}>
      {/* <div className="titleContent">
        <span>MANAGE VALUE</span>
      </div> */}
      <div
        style={{ width: "15%", position: "absolute", top: "20%", left: "1%" }}
      >
        <SM
          label="Key filter"
          placeholder="Pick one"
          data={allKey}
          onChange={(e) => {
            if (e === "All") {
              setSelectKey("");
            } else {
              setSelectKey(e);
            }
            // setIsLoading()
          }}
          searchable
        />
      </div>
      <div>
        <div style={{ width: "70%", margin: "0 0 2vh 25%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              fontSize: "1.5rem",
              fontWeight: "600",
              color: "rgb(0 124 196)",
            }}
          >
            <span style={{ width: "40%", textAlign: "center" }}>Key</span>
            <span style={{ width: "60%", textAlign: "center" }}>Value</span>
          </div>
        </div>
        <div
          id="listValue"
          style={{
            maxHeight: "63vh",
            overflow: "auto",
            margin: "2vh 0",
            border: "solid 1px gray",
            width: "70%",
            margin: "0 0 2vh 25%",
            overflowX:"hidden"
          }}
        >
          {selectKey === ""
            ? allValue.map((u, index) => (
                <div
                  key={u.id_key_value}
                  style={{
                    display: "flex",
                    width: "100%",
                    border: "solid 1px gray",
                    backgroundColor:index%2===0?"#c0c0c091":"white"
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      textAlign: "center",
                      display: "flex",
                      justifyItems: "center",
                      borderRight: "solid 1px",
                    }}
                  >
                    <div>
                      <IconButton
                        className="iconAdd"
                        onClick={() => {
                          deleteKeyValue(u.id_key_value);
                        }}
                      >
                        <IconCircleMinus color="red" />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "flex",
                        fontSize: "1.1rem",
                        margin: "1vh",
                      }}
                    >
                      <span>{u.key}</span>
                    </div>
                  </div>
                  <div
                    className="inputError"
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "1.1rem",
                      margin: "1vh",
                    }}
                  >
                    {u.value}
                  </div>
                </div>
              ))
            : allValue
                .filter((i) => i.key === selectKey)
                .map((u, index) => (
                  <div
                    key={u.id_key_value}
                    style={{
                      display: "flex",
                      width: "100%",
                      border: "solid 1px gray",
                      backgroundColor:index%2===0?"#c0c0c091":"white"
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        textAlign: "center",
                        display: "flex",
                        justifyItems: "center",
                        borderRight: "solid 1px",
                      }}
                    >
                      <div>
                        <IconButton
                          className="iconAdd"
                          onClick={() => {
                            deleteKeyValue(u.id_key_value);
                          }}
                        >
                          <IconCircleMinus color="red" />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                          display: "flex",
                          fontSize: "1.1rem",
                          margin: "1vh",
                        }}
                      >
                        <span>{u.key}</span>
                      </div>
                    </div>
                    <div
                      className="inputError"
                      style={{
                        width: "60%",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "1.1rem",
                        margin: "1vh",
                      }}
                    >
                      {u.value}
                    </div>
                  </div>
                ))}
        </div>

        <div style={{ display: "flex", width: "70%", margin: "0 0 0 25%" }}>
          <div
            style={{
              width: "40%",
              textAlign: "center",
              display: "flex",
              justifyItems: "center",
            }}
          >
            <div style={{ border: "none 1px" }}>
              <IconButton
                className="iconAdd"
                onClick={() => {
                  addKeyValue();
                }}
              >
                <IconCirclePlus color="green" />
              </IconButton>
            </div>
            <TextField
              // id="inputError"
              size="small"
              variant="outlined"
              label="Key"
              onChange={(e) => {
                setKeyValue({ ...keyValue, key: e.target.value });
              }}
              value={keyValue.key}
              style={{ margin: "0 10px 0 0", width: "100%" }}
            />
          </div>
          <div className="inputError" style={{ width: "57%" }}>
            <TextField
              id="inputError"
              size="small"
              variant="outlined"
              label="Value"
              onChange={(e) => {
                setKeyValue({ ...keyValue, value: e.target.value });
              }}
              value={keyValue.value}
              style={{ margin: "0", width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageValue;
