import './App.css';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import { Navigate, Route, Routes } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            JSON.parse(localStorage.getItem('user')) !== null && localStorage.getItem("user").search("token")!==-1? (
              <Home />
             ) : (
              <Login />
            )
          }
        />
        <Route
          path="/tool"
          element={
            JSON.parse(localStorage.getItem('user')) !== null && localStorage.getItem("user").search("token")!==-1?
              <Home />
              : <Navigate to={"/"} />
          }
        />
      </Routes> 
    </div>
  );
}

export default App;
