import { Input } from "@mantine/core";
import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { checkLogin } from "../../api/DeviceAPI";
import logo from "../../components/Header/logo.png";
const Login = () => {
  const [infoLogin, setInfoLogin] = useState({ username: "", password: "" });
  const handleLogin = async () => {
    try {
      if (infoLogin.username === "" || infoLogin.password === "") {
        toast.error("Information cannot be left blank");
      } else {
        const res = await axios.post(checkLogin, {
          username: infoLogin.username,
          password: infoLogin.password,
        });
        if (res.data.pCode === 2) {
          toast.error("Username does not exist");
        } else {
          if (res.data.pCode === 0) {
            toast.error("Password is not correct");
          } else {
            localStorage.setItem("user", JSON.stringify({token: res.data.accessToken, socketCheck: Date.now()}));
            window.location.reload();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        height: "90vh",
        backgroundColor: "#afafaf",
        padding: "10vh 0 0 0",
      }}
    >
      <ToastContainer />
      <div
        style={{
          border: "solid 1px white",
          height: "500px",
          width: "400px",
          margin: "0vh 0 0 37.5%",
          borderRadius: "5px",
          backgroundColor: "white",
          boxShadow: "0px 10px 35px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            color: "rgb(0 124 196)",
            fontSize: "2rem",
            fontWeight: 800,
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            margin: "15% 0 0 0",
          }}
        >
          <span>NETW</span>
          <img src={logo} />
          <span>RK TOOL</span>
        </div>
        <div style={{ color: "rgb(0 124 196)" }}>
          <div
            style={{
              width: "70%",
              margin: "3vh 0 0vh 15%",
              display: "flex",
              flexFlow: "column",
            }}
          >
            <span
              style={{ fontSize: "1rem", fontWeight: 650, margin: "1vh 0" }}
            >
              User name
            </span>
            <Input
              size="lg"
              value={infoLogin.username}
              onChange={(e) => {
                setInfoLogin({ ...infoLogin, username: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            ></Input>
          </div>

          <div
            style={{
              width: "70%",
              margin: "1vh 15%",
              display: "flex",
              flexFlow: "column",
            }}
          >
            <span
              style={{ fontSize: "1rem", fontWeight: 650, margin: "1vh 0" }}
            >
              Password
            </span>
            <Input
              type={"password"}
              size="lg"
              value={infoLogin.password}
              onChange={(e) => {
                setInfoLogin({ ...infoLogin, password: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            ></Input>
          </div>
        </div>
        <Button
          variant="contained"
          style={{ width: "30%", margin: "3vh 0 0 35%" }}
          onClick={() => handleLogin()}
        >
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default Login;
