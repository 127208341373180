import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import Header from "../../components/Header/Header";
import ManageCommand from "../../components/ManageCommand/ManageCommand";
import ManageError from "../../components/ManageError/ManageError";
import NetworkSetting from "../../components/NetworkSetting/NetworkSetting";
import AutoTest from "../../components/AutoTest/AutoTest";
import ManageValue from "../../components/ManageValue/ManageValue";
import jwt_decode from "jwt-decode";
import "./Home.css";
import axios from "axios";
import { checkToken, getAllStation, openTerminal } from "../../api/DeviceAPI";
import ManageItems from "../../components/ManageItems/ManageItems";
import TestDevice from "../../components/TestDevice/TestDevice";
const Home = () => {
  const [option, setOption] = useState("Op1");
  const [allStation, setAllStation] = useState({});
  const [socket, setSocket] = useState(null);
  console.log(JSON.parse(localStorage.getItem("user"))?.socketCheck)
  const user = jwt_decode(JSON.parse(localStorage.getItem("user"))?.token);
  // localStorage.setItem("log", "----- Wellcome -----\n")
  let stationName = ["AUS-1", "US1 01-08", "AU-dev", "AUS-only", "US-only"];
  if (stationName.includes(user?.full_name) === false) {
    localStorage.clear();
    window.location.reload();
  }
  
  useEffect(() => {
    setSocket(io(process.env.REACT_APP_SOCKET_SERVER_ADDRESS));
  }, []);

  // console.log(socket?.id)
  // socket?.emit("newUser", user.username);
  const getAllSta = async () => {
    let name = [];
    try {
      const res = await axios.post(getAllStation);

      setAllStation(res.data.pData);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(allStation)
  useEffect(() => {
    getAllSta();
  }, []);
  
  useEffect(() => {
    socket?.on("refuseNewUser", (data) => {
      toast.warning("Account is use. Please check and log in again!");
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 5000);
    });

    socket?.on('disconnect', ()=>{
      setTimeout(() => {
        socket?.emit("newUser", {email: user.username, time: JSON.parse(localStorage.getItem("user"))?.socketCheck});
        window.location.reload();
      }, 2000);
  });
  }, [socket]);
  
  switch (option) {
    case "Op1":
      socket?.emit("newUser", {email: user.username, time: JSON.parse(localStorage.getItem("user"))?.socketCheck});
      return (
        <div className="home">
          <ToastContainer />
          <div className="headerHome">
            <Header option={option} setOption={setOption} />
          </div>
          <div className="bodyHome">
            <div
              style={{
                display: "block",
                display: "block",
                width: "100%",
              }}
            >
              {/* <AutoTest socket={socket} user={user} STATION={allStation} /> */}
              <TestDevice socket={socket} user={user} STATION={allStation} />
            </div>
          </div>
        </div>
      );

    case "Op2":
      return (
        <div className="home">
          <ToastContainer />
          <div className="headerHome">
            <Header option={option} setOption={setOption} />
          </div>
          <div className="bodyHome">
            <div
              style={{
                display: "block",
                display: "block",
                width: "100%",
              }}
            >
              <ManageError />
            </div>
          </div>
        </div>
      );

    case "Op3":
      return (
        <div className="home">
          <ToastContainer />
          <div className="headerHome">
            <Header option={option} setOption={setOption} />
          </div>
          <div className="bodyHome">
            <div
              style={{
                display: "block",
                display: "block",
                width: "100%",
              }}
            >
              <ManageCommand />
            </div>
          </div>
        </div>
      );

    case "Op4":
      return (
        <div className="home">
          <ToastContainer />
          <div className="headerHome">
            <Header option={option} setOption={setOption} />
          </div>
          <div className="bodyHome">
            <div
              style={{
                display: "block",
                display: "block",
                width: "100%",
              }}
            >
              <NetworkSetting />
            </div>
          </div>
        </div>
      );

    case "Op5":
      return (
        <div className="home">
          <ToastContainer />
          <div className="headerHome">
            <Header option={option} setOption={setOption} />
          </div>
          <div className="bodyHome">
            <div
              style={{
                display: "block",
                display: "block",
                width: "100%",
              }}
            >
              <ManageValue />
            </div>
          </div>
        </div>
      );

    case "Op6":
      return (
        <div className="home">
          <ToastContainer />
          <div className="headerHome">
            <Header option={option} setOption={setOption} />
          </div>
          <div className="bodyHome">
            <div
              style={{
                display: "block",
                display: "block",
                width: "100%",
              }}
            >
              <ManageItems />
            </div>
          </div>
        </div>
      );
  }
  // return (
  //   <div className="home">
  //     <ToastContainer />
  //     <div className="headerHome">
  //       <Header option={option} setOption={setOption} />
  //     </div>
  //     <div className="bodyHome">
  //       {option === "Op1" && (
  //         <div
  //           style={{
  //             display: option === "Op1" ? "block" : "none",
  //             display: "block",
  //             width: "100%",
  //           }}
  //         >
  //           <TestDevice socket={socket} user={user} STATION={allStation} />
  //         </div>
  //       )}
  //       <div
  //         style={{
  //           display: option === "Op2" ? "block" : "none",
  //           width: "100%",
  //         }}
  //       >
  //         <ManageError />
  //       </div>
  //       <div
  //         style={{
  //           display: option === "Op3" ? "block" : "none",
  //           width: "100%",
  //         }}
  //       >
  //         <ManageCommand />
  //       </div>
  //       <div
  //         style={{
  //           display: option === "Op4" ? "block" : "none",
  //           width: "100%",
  //         }}
  //       >
  //         {option === "Op4" && <NetworkSetting />}
  //       </div>
  //       <div
  //         style={{
  //           display: option === "Op5" ? "block" : "none",
  //           width: "100%",
  //         }}
  //       >
  //         <ManageValue />
  //       </div>
  //       <div
  //         style={{
  //           display: option === "Op6" ? "block" : "none",
  //           width: "100%",
  //         }}
  //       >
  //         <ManageItems />
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Home;
