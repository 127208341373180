import { Button, Tooltip } from "@mui/material";
import { IconRouter } from "@tabler/icons-react";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import "./Header.css";
import logo from "./logo.png";
import jwt_decode from "jwt-decode";
const Header = ({ option, setOption }) => {
  const role = jwt_decode(JSON.parse(localStorage.getItem("user"))?.token);
  return (
    <div className="headerDiv" style={{ padding: "5px" }}>
      <div className="titleHeader">
        <span className="logoHeader">
          NETW
          <img src={logo} width="45px" />
          RK
          <IconRouter
            height={18}
            width={18}
            style={{ color: "rgb(222 83 48)", margin: "0 8px" }}
          />{" "}
          TOOL
        </span>
        <div className="optionHeader">
        <Button
          id="btnOption"
          variant={option === "Op1" ? "contained" : "outlined"}
          size="small"
          onClick={() => setOption("Op1")}
        >
          test device
        </Button>
        <Button
          id="btnOption"
          variant={option === "Op4" ? "contained" : "outlined"}
          style={{ display: role.role === 0 ? "block" : "none" }}
          size="small"
          onClick={() => setOption("Op4")}
        >
          Network setting
        </Button>
        <Button
          id="btnOption"
          variant={option === "Op3" ? "contained" : "outlined"}
          size="small"
          style={{ display: role.role === 0 ? "block" : "none" }}
          onClick={() => setOption("Op3")}
        >
          Manage command
        </Button>
        <Button
          id="btnOption"
          variant={option === "Op2" ? "contained" : "outlined"}
          style={{ display: role.role === 0 ? "block" : "none" }}
          size="small"
          onClick={() => setOption("Op2")}
        >
          Manage error
        </Button>
        <Button
          id="btnOption"
          variant={option === "Op5" ? "contained" : "outlined"}
          style={{ display: role.role === 0 ? "block" : "none" }}
          size="small"
          onClick={() => setOption("Op5")}
        >
          Manage value
        </Button>
        <Button
          id="btnOption"
          variant={option === "Op6" ? "contained" : "outlined"}
          style={{ display: role.role === 0 ? "block" : "none" }}
          size="small"
          onClick={() => setOption("Op6")}
        >
          Manage Items
        </Button>
        

        
      </div>
        <div style={{ position: "absolute", right: 15 }}>
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: 700,
              fontStyle: "italic",
              color: "rgb(0 124 196)",
            }}
          >
            Hello {role.username.toUpperCase()}
          </span>
          <Tooltip title="Logout">
            <Button
              style={{
                textTransform: "none",
                fontStyle: "italic",
                color: "orange",
              }}
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              <LogoutIcon fontSize="small" />
            </Button>
          </Tooltip>
        </div>
      </div>
      
    </div>
  );
};

export default Header;
