import { Button, IconButton, Pagination } from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector,
  gridClasses,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { IconFileDownload } from "@tabler/icons-react";
import { alpha, styled } from "@mui/material/styles";
import moment from "moment/moment";
import fileDownload from "js-file-download";
const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function ListItems(props) {
  let { rows, columns, getAllItems, setTimeSpace } = props;
  const [data, setData] = useState(rows);
  const [isLoading, setIsLoading] = useState(true);
  const [a, setA] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const CustomToolbar = () => {
    return (
      <div style={{ display: "flex" }}>
        <GridToolbarContainer sx={{ display: "flex" }}>
          <GridToolbarFilterButton style={{ fontSize: "0.8rem" }} />
          <GridToolbarExport style={{ fontSize: "0.8rem" }} />
        </GridToolbarContainer>
        <div
          style={{
            width: "20%",
            display: "flex",
            color: "#1976d2",
            fontSize: "0.8rem",
            marginTop: "5px",
            fontWeight:500
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <span style={{ width: "20%", textAlign: "right" }}>FROM: </span>
            <input
              style={{
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                color: "#1976d2",
                border: "solid #1976d2 1px",
                marginLeft: "5px",
                fontSize: "0.8rem",
                width: "40%",
                borderRadius: "3px",
              }}
              value={moment(from).format("YYYY-MM-DD")}
              type="date"
              onChange={(e) => {
                console.log(e.target.value);
                if (e.target.value.search("-") === -1) {
                  console.log("from NaN");
                  setFrom(e.target.value);
                  getAllItems();
                } else {
                  if (to.search("-") === -1) {
                    console.log("to NaN");
                    setFrom(e.target.value);
                  } else {
                      setTimeSpace(e.target.value, to);
                    setFrom(e.target.value);
                    
                  }
                }
              }}
            />
            <span style={{ width: "20%", textAlign: "right" }}>TO:</span>
            <input
              style={{
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                color: "#1976d2",
                border: "solid #1976d2 1px",
                marginLeft: "5px",
                fontSize: "0.8rem",
                width: "40%",
                borderRadius: "3px",
              }}
              value={to}
              type="date"
              onChange={(e) => {
                if (e.target.value.search("-") === -1) {
                  setTo(e.target.value);
                  getAllItems();
                } else {
                  if (from.search("-") === -1) {
                    setTo(e.target.value);
                  } else {
                    setTimeSpace(from, e.target.value);
                    setTo(e.target.value);
                  }
                }
              }}
            />
          </div>
          {/* <div style={{width:"50%", display:"flex", alignItems:"center"}}>
            
          </div> */}
        </div>
        {/* <IconButton style={{margin: "5px 0 0 5px"}} onClick={()=>{fileDownload(Arrays.asList(rows).toArray(new String[0]), "listItems.txt");}}>
          <IconFileDownload width={18} height={20} color="#1976d2" />
        </IconButton> */}
      </div>
    );
  };
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <>
        <Pagination
          color="primary"
          size="small"
          count={pageCount}
          page={page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        ></Pagination>
      </>
    );
  }

  useEffect(() => {
    var temp = [];
    var index = 1;
    rows.forEach((row) => {
      temp.push({ ...row, id_table: index });
      index++;
    });
    setData(temp);
    setIsLoading(false);
  }, [rows]);

  return (
    <>
      {!isLoading && (
        <StripedDataGrid
          getRowId={(row) => row.id_table}
          initialState={{
              sortModel: [{ field: "created_at", sort: "desc" }],
              sorting: {
            },
          }}
          rows={data}
          columns={columns}
          rowHeight={35}
          // hideFooter="true"
          pageSize={100}
          hideFooterRowCount="true"
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          disableColumnMenu
          disableSelectionOnClick
          sx={{
            fontSize: "0.7rem",
            backgroundColor: "white",
            overflowX: "hidden",
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
      )}
    </>
  );
}

export default ListItems;
