import { Modal, Radio, Select } from "@mantine/core";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { IconCheck, IconTrashX, IconX } from "@tabler/icons-react";
import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import {
  addGroup,
  addModel,
  checkActionModel,
  deleteGroup,
  deleteModel,
  editGroup,
  editModel,
  getAllCmdOfGroup,
  getAllCmdOfModel,
  getAllGroup,
  getAllGroupOfModel,
  getAllModel,
  getAllModelOfGroup,
} from "../../api/DeviceAPI";
import "./ManageCommand.css";
const ManageCommand = () => {
  const [isloading, setIsLoading] = useReducer((x) => x + 1, 0);
  const [listGroup, setListGroup] = useState([]);
  const [listModel, setListModel] = useState([]);
  const [listNameModel, setListNameModel] = useState([]);
  const [listNameGroup, setListNameGroup] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [modelName, setModelName] = useState("");
  const [valueRadio, setValueRadio] = useState("");
  const [listModelAdd, setListModelAdd] = useState([]);
  const [listCmdAdd, setListCmdAdd] = useState([]);
  const [listGroupOfModel, setListGroupOfModel] = useState([]);
  const [openedAddGroup, setOpenedAddGroup] = useState(false);
  const [openedAddModel, setOpenedAddModel] = useState(false);
  const [openedShowGroup, setOpenedShowGroup] = useState(false);
  const [openedShowModel, setOpenedShowModel] = useState(false);
  const [modelOfGroup, setModelOfGroup] = useState([]);
  const [modelValid, setModelValid] = useState([]);
  const [groupValid, setGroupValid] = useState([]);
  const [modelChange, setModelChange] = useState([]);
  const [groupChange, setGroupChange] = useState([]);
  const [listGroupAndCommand, setListGroupAndCommand] = useState([]);
  const [modelTypeInit, setModelTypeInit] = useState("");
  const [cmdString, setCmdString] = useState("");
  const [cmdChange, setCmdChange] = useState([]);
  const [selectGroup, setSelectGroup] = useState({
    id_group: "",
    group_name: "",
    group_action: "",
  });

  const getAllGroupsAndModels = async () => {
    let temp = [];
    let temp1 = [];
    try {
      const res1 = await axios.post(getAllGroup);
      const res2 = await axios.post(getAllModel);
      for (let i = 0; i < res2.data.pData.length; i++) {
        if (res2.data.pData[i].model_type === "Group") {
          temp.push(res2.data.pData[i].id_model);
        }
      }
      for (let i = 0; i < res1.data.pData.length; i++) {
        temp1.push(res1.data.pData[i].group_name);
      }

      setListNameModel(temp);
      setListNameGroup(temp1);
      setListGroup(
        res1.data.pData.sort(function (a, b) {
          if (a.group_action > b.group_action) {
            return -1;
          }
          if (a.group_action < b.group_action) {
            return 1;
          }
          return 0;
        })
      );
      setListModel(
        res2.data.pData.sort(function (a, b) {
          if (a.id_model < b.id_model) {
            return -1;
          }
          if (a.id_model > b.id_model) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addNewGroup = async () => {
    let temp = [];
    try {
      for (let i = 0; i < listModelAdd.length; i++) {
        const check = await axios.post(checkActionModel, {
          id_model: listModelAdd[i],
          action: valueRadio,
        });
        if (check.data.pCode === 1) {
          toast.error(
            "Model " +
              listModelAdd[i] +
              " is already in the group " +
              check.data.group
          );
        } else {
          temp.push(listModelAdd[i]);
        }
      }

      if (listModelAdd.length === temp.length) {
        const res = await axios.post(addGroup, {
          group_name: groupName,
          action: valueRadio,
          created_by: "Admin",
          model: listModelAdd,
          command: listCmdAdd,
        });

        if (res.data.pCode === 1) {
          toast.success("Add group success!");
          setListCmdAdd([]);
          setListModelAdd([]);
          setOpenedAddGroup(false);
          setValueRadio("");
          setGroupName("");
          setIsLoading();
        } else {
          toast.error("Add group fail");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addNewModel = async () => {
    const id_group = listGroup.filter((item) => item.group_name === groupName);
    console.log(id_group);
    try {
      if (modelName === "" || groupName === "") {
        toast.error("Information cannot be left blank");
      } else {
        const res = await axios.post(addModel, {
          id_model: modelName,
          model_type: valueRadio,
          id_group: id_group.length > 0 ? id_group[0].id_group : null,
          command: listCmdAdd,
        });
        if (res.data.pCode === 1) {
          toast.success("Add model success!");
          setListCmdAdd([]);
          setModelName("");
          setOpenedAddModel(false);
          setGroupName("");
          setIsLoading();
        } else {
          toast.error(res.data.pMess);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const settingGroup = async () => {
    let temp = [];
    try {
      if (listModelAdd.length > 0) {
        for (let i = modelValid.length; i < listModelAdd.length; i++) {
          const check = await axios.post(checkActionModel, {
            id_model: listModelAdd[i],
            action: selectGroup.group_action,
          });
          if (check.data.pCode === 1) {
            toast.error(
              "Model " +
                listModelAdd[i] +
                " is already in the group " +
                check.data.group
            );
          } else {
            temp.push(listModelAdd[i]);
          }
        }
      }
      if (listModelAdd.length - modelValid.length <= temp.length) {
        const res = await axios.post(editGroup, {
          id_group: selectGroup.id_group,
          group_name: selectGroup.group_name,
          model_change: modelChange,
          cmd_change: cmdChange,
        });
        if (res.data.pCode === 1) {
          toast.success("Edit group success!");
          setListCmdAdd([]);
          setOpenedShowGroup(false);
          setListModelAdd([]);
          setModelChange([]);
          setCmdChange([]);
          setIsLoading();
        } else {
          toast.error(res.data.pMess);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const settingModel = async () => {
    let listAction = [];
    let temp = 0;

    try {
      const res = await axios.post(getAllGroupOfModel, {
        id_model: selectGroup.id_model,
      });

      if (groupChange.length > 0) {
        res.data.pData.map((u) => {
          listAction.push(u.group_action);
        });

        groupChange
          .filter((x) => x.status === "add")
          .map((t) => {
            console.log(
              groupChange.filter((x) => x.id === t.id && x.status === "add")
                .length
            );
            if (
              groupChange.filter((x) => x.id === t.id && x.status === "add")
                .length !==
              groupChange.filter((x) => x.id === t.id && x.status === "delete")
                .length
            ) {
              if (
                listAction.includes(
                  listGroup.filter((i) => i.id_group === t.id)[0].group_action
                )
              ) {
                toast.error(
                  "Model " +
                    selectGroup.id_model +
                    " already exists in a group with action " +
                    listGroup.filter((i) => i.id_group === t.id)[0].group_action
                );
                temp = 1;
              }
            }
          });
        if (temp === 0) {
          const res = await axios.post(editModel, {
            id_model: selectGroup.id_model,
            type: selectGroup.model_type,
            type_init: modelTypeInit,
            group_change: groupChange,
            cmd_change: cmdChange,
            groupOfModel: listGroupOfModel,
          });
          if (res.data.pCode === 1) {
            toast.success("Edit model success!");
            setListCmdAdd([]);
            setOpenedShowModel(false);
            setListModelAdd([]);
            setGroupChange([]);
            setCmdChange([]);
            setListGroupOfModel([]);
            setListGroupAndCommand([]);
            setIsLoading();
          } else {
            toast.error(res.data.pMess);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTheGroup = async (id) => {
    try {
      const res = await axios.post(deleteGroup, { id: id });
      if (res.data.pCode === 1) {
        toast.success("Delete group success!");
        setIsLoading();
      } else {
        toast.error(res.data.pMess);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTheModel = async (id) => {
    try {
      const res = await axios.post(deleteModel, { id: id });
      if (res.data.pCode === 1) {
        toast.success("Delete model success!");
        setIsLoading();
      } else {
        toast.error(res.data.pMess);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllGroupsAndModels();
  }, [isloading]);

  return (
    <div className="viewHome">
      {/* <div className="titleContent">
        <span>MANAGE COMMAND</span>
      </div> */}
      <div className="ManageCmd">
        <div className="div1">
          <div className="title">Group</div>
          <div className="option1">
            <Button
              size="small"
              variant="contained"
              onClick={() => setOpenedAddGroup(true)}
            >
              + group
            </Button>
          </div>
          <div className="listGroup">
            {listGroup.map((u) => (
              <div style={{ display: "flex", fontSize: "0.8rem" }}>
                <div
                  className="infoGroup"
                  onClick={async () => {
                    let temp = [];
                    let temp1 = [];
                    const res = await axios.post(getAllModelOfGroup, {
                      id_group: u.id_group,
                    });
                    const res1 = await axios.post(getAllCmdOfGroup, {
                      id_group: u.id_group,
                    });
                    for (let i = 0; i < res.data.pData.length; i++) {
                      temp.push(res.data.pData[i].id_model);
                    }
                    for (let j = 0; j < res1.data.pData.length; j++) {
                      temp1.push(res1.data.pData[j].cmd_message);
                    }
                    setListCmdAdd(temp1);
                    setListModelAdd(temp);
                    setModelValid(temp);
                    setModelOfGroup(res.data.pData);
                    setSelectGroup(u);
                    setOpenedShowGroup(true);
                  }}
                >
                  <div style={{ position: "absolute", left: 0, width: "30%" }}>
                    {u.group_name}
                  </div>
                  <div
                    style={{
                      textTransform: "uppercase",
                      position: "absolute",
                      left: "60%",
                      width: "30%",
                    }}
                  >
                    {u.group_action}
                  </div>
                  <div
                    style={{ position: "absolute", left: "80%", width: "30%" }}
                  >
                    {u.created_by}
                  </div>
                </div>
                <div style={{ width: "10%" }}>
                  <IconButton
                    onClick={() => {
                      setOpenedShowGroup(false);
                      toast.info(
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <p>Want to delete this group?</p>
                          <div>
                            <IconButton
                              onClick={() => deleteTheGroup(u.id_group)}
                            >
                              <IconCheck color="green" />
                            </IconButton>
                            <IconButton>
                              <IconX color="red" />
                            </IconButton>
                          </div>
                        </div>
                      );
                    }}
                    disabled={
                      u.group_name.split("_")[0].toLocaleLowerCase() ===
                      "default"
                        ? true
                        : false
                    }
                  >
                    <IconTrashX
                      style={{}}
                      color={
                        u.group_name.split("_")[0].toLocaleLowerCase() ===
                        "default"
                          ? "gray"
                          : "red"
                      }
                      width="20"
                      height={18}
                    />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="div2">
          <div className="title">Model</div>
          <div className="option2">
            <Button
              size="small"
              variant="contained"
              onClick={() => setOpenedAddModel(true)}
            >
              + model
            </Button>
          </div>
          <div className="listModel">
            {listModel.map((u) => (
              <div style={{ display: "flex", fontSize: "0.8rem" }}>
                <div
                  className="infoGroup"
                  onClick={async () => {
                    let temp = [];
                    let temp1 = [];
                    let temp2 = listGroupAndCommand;
                    const res = await axios.post(getAllGroupOfModel, {
                      id_model: u.id_model,
                    });
                    for (let i = 0; i < res.data.pData.length; i++) {
                      temp.push(res.data.pData[i].group_name);
                      const res_1 = await axios.post(getAllCmdOfGroup, {
                        id_group: res.data.pData[i].id_group,
                      });
                      // console.log(res_1.data.pData);
                      temp2.push(res_1.data.pData);
                    }
                    const res1 = await axios.post(getAllCmdOfModel, {
                      id_model: u.id_model,
                    });
                    for (let j = 0; j < res1.data.pData.length; j++) {
                      temp1.push(res1.data.pData[j].cmd_message);
                    }
                    setModelTypeInit(u.model_type);
                    setListCmdAdd(temp1);
                    setListGroupOfModel(res.data.pData);
                    setGroupValid(res.data.pData);
                    setListGroupAndCommand(temp2);
                    setSelectGroup(u);
                    setOpenedShowModel(true);
                  }}
                >
                  <div>{u.id_model}</div>
                </div>
                <div style={{ width: "10%" }}>
                  <IconButton
                    onClick={() => {
                      setOpenedShowGroup(false);
                      toast.info(
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <p>Want to delete this model?</p>
                          <div>
                            <IconButton
                              onClick={() => deleteTheModel(u.id_model)}
                            >
                              <IconCheck color="green" />
                            </IconButton>
                            <IconButton>
                              <IconX color="red" />
                            </IconButton>
                          </div>
                        </div>
                      );
                    }}
                  >
                    <IconTrashX color="red" width="20" height={18} />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Modal add group */}
      <Modal
        opened={openedAddGroup}
        onClose={() => {
          setListCmdAdd([]);
          setListModelAdd([]);
          setOpenedAddGroup(false);
          setValueRadio("");
          setGroupName("");
          setCmdString("");
        }}
        title={
          <div style={{ fontSize: "1.3rem", fontWeight: "700" }}>
            Create new group
          </div>
        }
      >
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          Group name:
        </span>
        <br></br>
        <TextField
          size="small"
          fullWidth
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
          }}
        ></TextField>
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>Action:</span>
        <br></br>
        <Radio.Group value={valueRadio} onChange={(e) => setValueRadio(e)}>
          <Radio value={"test"} label="Auto-test" />
          <Radio value={"UpdateIOS"} label="Update IOS" />
          <Radio value={"Config"} label="Configure" />
          <Radio value={"break"} label="Break password" />
        </Radio.Group>
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          Add model:{" "}
        </span>
        <br></br>

        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          {listModelAdd.map((u, index) => (
            <div key={index} style={{ margin: "1vh 3%" }}>
              <Tooltip title="Remove" placement="left">
                <p
                  className="modelAdd"
                  onClick={() => {
                    setListModelAdd(listModelAdd.filter((item) => item !== u));
                  }}
                >
                  {u}
                </p>
              </Tooltip>
            </div>
          ))}
        </div>
        <Select
          data={listNameModel}
          searchable
          value={listModelAdd}
          onChange={(e) => {
            if (listModelAdd.includes(e) === false) {
              setListModelAdd((listModelAdd) => [...listModelAdd, e]);
            }
          }}
        />
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          Add command:
        </span>
        <br></br>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            flexFlow: "column",
          }}
        >
          {listCmdAdd.map((u, index) => (
            <div key={index} style={{ margin: "0.5vh 3%" }}>
              <Tooltip title="Remove" placement="left">
                <p
                  className="modelAdd"
                  onClick={() => {
                    setListCmdAdd(listCmdAdd.filter((item) => item !== u));
                  }}
                >
                  {u}
                </p>
              </Tooltip>
            </div>
          ))}
        </div>

        <textarea
          size="small"
          style={{ width: "100%", minHeight: cmdString.length + 100 }}
          placeholder="Enter the commands to add"
          value={cmdString}
          onChange={(e) => {
            setCmdString(e.target.value);
          }}
        ></textarea>
        <Button
          size="small"
          variant="outlined"
          style={{ width: "10%", marginLeft: "80%" }}
          onClick={() => {
            if (cmdString.split("\n").length > 1) {
              for (let i = 0; i < cmdString.split("\n").length; i++) {
                setListCmdAdd((listCmdAdd) => [
                  ...listCmdAdd,
                  cmdString.split("\n")[i],
                ]);
              }
              setTimeout(() => {
                setCmdString("");
              }, 200);
            } else {
              if (cmdString !== "") {
                setListCmdAdd((listCmdAdd) => [...listCmdAdd, cmdString]);
                setTimeout(() => {
                  setCmdString("");
                }, 200);
              }
            }
          }}
        >
          add
        </Button>

        {/* <TextField
          size="small"
          fullWidth
          placeholder="Enter to add the command"
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value !== "") {
              setListCmdAdd((listCmdAdd) => [...listCmdAdd, e.target.value]);
              e.target.value = "";
            }
          }}
        ></TextField> */}
        <div
          style={{ width: "100%", textAlign: "center", margin: "2vh 0 0 0" }}
        >
          <Button
            variant="contained"
            sx={{ textTransform: "none", margin: "0 5% 0 0" }}
            onClick={() => addNewGroup()}
          >
            Create
          </Button>
        </div>
      </Modal>
      {/* Modal add Model */}
      <Modal
        opened={openedAddModel}
        onClose={() => {
          setListCmdAdd([]);
          setModelName("");
          setOpenedAddModel(false);
          setGroupName("");
        }}
        title={
          <div style={{ fontSize: "1.3rem", fontWeight: "700" }}>
            Create new model
          </div>
        }
      >
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
          Model name (PID):
        </span>
        <br></br>
        <TextField
          size="small"
          fullWidth
          value={modelName}
          onChange={(e) => {
            setModelName(e.target.value);
          }}
        ></TextField>
        <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>Type:</span>
        <br></br>
        <Radio.Group value={valueRadio} onChange={(e) => setValueRadio(e)}>
          <Radio value={"Group"} label="Group" />
          <Radio value={"Single"} label="Single" disabled />
        </Radio.Group>
        <div style={{ display: valueRadio === "Group" ? "block" : "none" }}>
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>Group:</span>
          <br></br>
          <Select
            data={listNameGroup}
            searchable
            onChange={(e) => {
              setGroupName(e);
            }}
          />
        </div>
        <div style={{ display: valueRadio === "Single" ? "block" : "none" }}>
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
            Add command:
          </span>
          <br></br>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              flexFlow: "column",
            }}
          >
            {listCmdAdd.map((u, index) => (
              <div key={index} style={{ margin: "0.5vh 3%" }}>
                <Tooltip title="Remove" placement="left">
                  <p
                    className="modelAdd"
                    onClick={() => {
                      setListCmdAdd(listCmdAdd.filter((item) => item !== u));
                    }}
                  >
                    {u}
                  </p>
                </Tooltip>
              </div>
            ))}
          </div>
          <TextField
            size="small"
            fullWidth
            placeholder="Enter to add the command"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value !== "") {
                setListCmdAdd((listCmdAdd) => [...listCmdAdd, e.target.value]);
                e.target.value = "";
              }
            }}
          ></TextField>
        </div>

        <div
          style={{ width: "100%", textAlign: "center", margin: "2vh 0 0 0" }}
        >
          <Button
            variant="contained"
            sx={{ textTransform: "none", margin: "0 5% 0 0" }}
            onClick={() => addNewModel()}
          >
            Create
          </Button>
        </div>
      </Modal>
      {/* show group and edit */}
      {selectGroup && (
        <Modal
          opened={openedShowGroup}
          onClose={() => {
            setListCmdAdd([]);
            setOpenedShowGroup(false);
            setListModelAdd([]);
            setModelChange([]);
            setCmdChange([]);
            setListGroupOfModel([]);
            setCmdString("");
          }}
          title={
            <div style={{ fontSize: "1.3rem", fontWeight: "700" }}>
              Setting Group
            </div>
          }
        >
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
            Group name:
          </span>
          <br></br>
          <TextField
            size="small"
            fullWidth
            value={selectGroup.group_name}
            onChange={(e) => {
              setSelectGroup({ ...selectGroup, group_name: e.target.value });
            }}
          ></TextField>
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>Action:</span>
          <span> {selectGroup.group_action}</span>
          <br></br>
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
            Model of group:
          </span>
          <br></br>
          <div className="listModelInGroup" style={{ display: "flex", width: "100%", flexWrap: "wrap" , maxHeight:"45vh", overflow:"auto"}}>
            {listModelAdd.map((u, index) => (
              <div key={index} style={{ margin: "1vh 3%" }}>
                <Tooltip title="Remove" placement="left">
                  <p
                    className="modelAdd"
                    onClick={() => {
                      setListModelAdd(
                        listModelAdd.filter((item) => item !== u)
                      );
                      setModelChange((modelChange) => [
                        ...modelChange,
                        { id: u, status: "delete" },
                      ]);
                    }}
                  >
                    {u}
                  </p>
                </Tooltip>
              </div>
            ))}
          </div>
          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "600",
              display: selectGroup.group_name
                ? selectGroup.group_name.split("_")[0].toLocaleLowerCase() ===
                  "default"
                  ? "none"
                  : "block"
                : "block",
            }}
          >
            Add model:
          </span>
          <br></br>
          <Select
            data={listNameModel}
            searchable
            value={listModelAdd}
            onChange={(e) => {
              if (listModelAdd.includes(e) === false) {
                setListModelAdd((listModelAdd) => [...listModelAdd, e]);
                setModelChange((modelChange) => [
                  ...modelChange,
                  { id: e, status: "add" },
                ]);
              }
            }}
            style={{
              display: selectGroup.group_name
                ? selectGroup.group_name.split("_")[0].toLocaleLowerCase() ===
                  "default"
                  ? "none"
                  : "block"
                : "block",
            }}
          />
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
            Edit command:
          </span>
          <br></br>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              flexFlow: "column",
            }}
          >
            {listCmdAdd.map((u, index) => (
              <div key={index} style={{ margin: "0.5vh 3%" }}>
                <Tooltip title="Remove" placement="left">
                  <p
                    className="modelAdd"
                    onClick={() => {
                      setListCmdAdd(listCmdAdd.filter((item) => item !== u));
                      setCmdChange((cmdChange) => [
                        ...cmdChange,
                        { mess: u, status: "delete" },
                      ]);
                    }}
                  >
                    {u}
                  </p>
                </Tooltip>
              </div>
            ))}
          </div>
          <textarea
            size="small"
            style={{ width: "100%", minHeight: cmdString.length + 100 }}
            placeholder="Enter the commands to add"
            value={cmdString}
            onChange={(e) => {
              setCmdString(e.target.value);
            }}
          ></textarea>
          <Button
            size="small"
            variant="outlined"
            style={{ width: "10%", marginLeft: "80%" }}
            onClick={() => {
              if (cmdString.split("\n").length > 1) {
                for (let i = 0; i < cmdString.split("\n").length; i++) {
                  setListCmdAdd((listCmdAdd) => [
                    ...listCmdAdd,
                    cmdString.split("\n")[i],
                  ]);
                  setCmdChange((cmdChange) => [
                    ...cmdChange,
                    { mess: cmdString.split("\n")[i], status: "add" },
                  ]);
                }
                setTimeout(() => {
                  setCmdString("");
                }, 200);
              } else {
                if (cmdString !== "") {
                  setListCmdAdd((listCmdAdd) => [...listCmdAdd, cmdString]);
                  setCmdChange((cmdChange) => [
                    ...cmdChange,
                    { mess: cmdString, status: "add" },
                  ]);
                  setTimeout(() => {
                    setCmdString("");
                  }, 200);
                }
              }
            }}
          >
            add
          </Button>
          <div
            style={{ width: "100%", textAlign: "center", margin: "2vh 0 0 0" }}
          >
            <Button
              variant="contained"
              sx={{ textTransform: "none", margin: "0 5% 0 0" }}
              onClick={() => settingGroup()}
            >
              Save
            </Button>
          </div>
        </Modal>
      )}
      {/* show model and edit */}
      {selectGroup && (
        <Modal
          opened={openedShowModel}
          onClose={() => {
            setListCmdAdd([]);
            setOpenedShowModel(false);
            setListModelAdd([]);
            setGroupChange([]);
            setCmdChange([]);
            setListGroupOfModel([]);
            setListGroupAndCommand([]);
          }}
          title={
            <div style={{ fontSize: "1.3rem", fontWeight: "700" }}>
              Setting Model
            </div>
          }
        >
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
            Model name (PID):
          </span>
          <br></br>
          <TextField
            size="small"
            fullWidth
            value={selectGroup.id_model}
            onChange={(e) => {
              setSelectGroup({ ...selectGroup, group_name: e.target.value });
            }}
          ></TextField>
          <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>Type:</span>
          <br></br>
          <Radio.Group
            value={selectGroup.model_type}
            onChange={(e) => {
              setSelectGroup({ ...selectGroup, model_type: e });
            }}
          >
            <Radio value={"Group"} label="Group" />
            <Radio value={"Single"} label="Single" />
          </Radio.Group>

          <div
            style={{
              display: selectGroup.model_type === "Group" ? "block" : "none",
            }}
          >
            <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
              Group of model:
            </span>
            <br></br>
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {listGroupOfModel.map((u, index) => (
                <>
                  <div key={index} style={{ margin: "1vh 3%" }}>
                    {/* <Tooltip title="Remove" placement="left"> */}
                    <p
                      className="modelAdd"
                      onClick={() => {
                        setListGroupOfModel(
                          listGroupOfModel.filter(
                            (item) => item.group_name !== u.group_name
                          )
                        );
                        setGroupChange((groupChange) => [
                          ...groupChange,
                          {
                            id: listGroupOfModel.filter(
                              (item) => item.group_name === u.group_name
                            )[0].id_group,
                            status: "delete",
                          },
                        ]);
                      }}
                    >
                      {u.group_name}({u.group_action})
                    </p>
                    {/* </Tooltip> */}
                  </div>
                  <div>
                    {listGroupAndCommand[index] &&
                      listGroupAndCommand[index].map((m) => (
                        <p style={{ marginLeft: "5%" }}>
                          <i>{m.cmd_message}</i>
                        </p>
                      ))}
                  </div>
                </>
              ))}
            </div>
            <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
              Add group for model:
            </span>
            <br></br>
            <Select
              data={listNameGroup}
              searchable
              value={listGroupOfModel}
              onChange={(e) => {
                if (
                  listGroupOfModel.filter((item) => item.group_name === e)
                    .length == 0
                ) {
                  setListGroupOfModel((listGroupOfModel) => [
                    ...listGroupOfModel,
                    listGroup.filter((item) => item.group_name === e)[0],
                  ]);

                  setGroupChange((groupChange) => [
                    ...groupChange,
                    {
                      id: listGroup.filter((item) => item.group_name === e)[0]
                        .id_group,
                      status: "add",
                    },
                  ]);
                }
              }}
            />
          </div>
          <div
            style={{
              display: selectGroup.model_type === "Single" ? "block" : "none",
            }}
          >
            <span style={{ fontSize: "0.9rem", fontWeight: "600" }}>
              Change command:
            </span>
            <br></br>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                flexFlow: "column",
              }}
            >
              {listCmdAdd.map((u, index) => (
                <div key={index} style={{ margin: "0.5vh 3%" }}>
                  <Tooltip title="Remove" placement="left">
                    <p
                      className="modelAdd"
                      onClick={() => {
                        setListCmdAdd(listCmdAdd.filter((item) => item !== u));
                        setCmdChange((cmdChange) => [
                          ...cmdChange,
                          { mess: u, status: "delete" },
                        ]);
                      }}
                    >
                      {u}
                    </p>
                  </Tooltip>
                </div>
              ))}
            </div>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter to add the command"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value !== "") {
                  setListCmdAdd((listCmdAdd) => [
                    ...listCmdAdd,
                    e.target.value,
                  ]);
                  console.log(e.target.value);
                  setCmdChange((cmdChange) => [
                    ...cmdChange,
                    { mess: e.target.value, status: "add" },
                  ]);
                  setTimeout(() => {
                    e.target.value = "";
                  }, 200);
                }
              }}
            ></TextField>
          </div>
          <div
            style={{ width: "100%", textAlign: "center", margin: "2vh 0 0 0" }}
          >
            <Button
              variant="contained"
              sx={{ textTransform: "none", margin: "0 5% 0 0" }}
              onClick={() => {
                settingModel();
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ManageCommand;
